import React, { useEffect, useState } from "react";
import { getDownloadIcon } from "../views/components/util";
import { FaBeer, FaHeart, FaAnchor, FaUser } from 'react-icons/fa';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonPlay } from 'pure-react-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ReactComponent as PlayIcon } from '../public/images/play.svg';
import { ReactComponent as PauseIcon } from '../public/images/pause.svg';
import '../App.css';
// import './debt-listing.css';

function DebtListing(props) {
    const [data, setData] = useState();
    const [totalBondsValue, setTotalBondsValue] = useState(0);
    const [totalOutstandingBondsValue, setTotalOutstandingBondsValue] = useState(0);
    const [esgLableBonds, setESGLableBonds] = useState(0);
    const [numberOfIssuer, setNumberOfIssuer] = useState(0);
    const [uniqueIsin, setUniqueIsin] = useState(0);
    const baseApi = '/api/debt-listing';
    const [visibleSlide, setVisibleslides] = useState(7);
    const images = [
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/axis.jpg", alt: "axis" },
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/hdfc.png", alt: "hdfc" },
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/Iifl.png", alt: "IIFL" },
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/indian-oil.png", alt: "indian-oil" },
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/future-track.jpg", alt: "future-track" },
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/ireda.png", alt: "IREDA" },
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/Ntpc.png", alt: "NTPC" },
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/Pfc.png", alt: "pfc" },
        { src: "https://www.nseix.com/nseixcms/sites/default/files/2022-01/Rec.png", alt: "Rec" }
    ];
    const issuerDataApi = '/api/issuer-data';
    const [mtnInUSD, setMtnInUSD] = useState(0);
    const [debtListingUnderMtnInUSD, setDebtListingUnderMtnInUSD] = useState(0);
    const [debtListingUnderStandaloneInUSD, setDebtListingUnderStandaloneInUSD] = useState(0)
    const [gssBondsInUSD, setGssBondsInUSD] = useState(0);
    const [mtnData, setMtnData] = useState([]);
    const [isExpanded,setIsExpanded] = useState(false);


    useEffect(() => {
        fetchData();
        loadIssuerData(issuerDataApi);
    }, []);

    const loadIssuerData = async (api) => {
        fetch(api)
            .then((resp) => resp.json())
            .then((data) => {
                setMtnInUSD(data[0].TOTAL_MTN_IN_USD_MN);
                setDebtListingUnderMtnInUSD(data[0].TOTAL_DEBT_LISTING_UNDER_MTN_IN_USD_MN);
                setDebtListingUnderStandaloneInUSD(data[0].TOTAL_DEBT_LISTING_UNDER_STANDALONE_IN_USD_MN);
                setGssBondsInUSD(data[0].TOTAL_GSS_BONDS_IN_USD_MN);
            })
            .catch((e) => {
                console.log("error in loading table data", e);
            });
    }

    const fetchData = async () => {
        try {
            const resp = await fetch(baseApi);
            console.log("Response is >>>", resp);
            const jsonData = await resp.json();
            console.log("Json data is >>>", jsonData);
            setTotalBondsValue(jsonData.totalListedValueInMn);
            setTotalOutstandingBondsValue(jsonData.OutStandingBondsValue);
            setESGLableBonds(jsonData.totalEsgformattedValueInMn);
            setNumberOfIssuer(jsonData.numberOfIssuer[0].TOTAL_ISSUER);
            setUniqueIsin(jsonData.uniqueISIN[0].TOTAL_COUNT);
            setMtnData(jsonData.mtnValue);
        } catch (error) {
            console.log('error in fetching data', error);
        };
    };

    const handleMtn = () => {
        setIsExpanded(!isExpanded)
    }

    const style = {
        paddingBottom: '3%',
        width: '10%'
    }

    const carouselStyle = {
        width: "166.667%",
        transform: `translateX(0%) translateX(0px)`,
        flexDirection: "row"
    }

    const countStyle = {
        color: 'red'
    }

    const stats = [
        { id: 1, icon: <FaBeer />, count: 2630, label: "Total listed bonds" },
        { id: 2, icon: <FaHeart />, count: 921, label: "Outstanding Listed Bonds" },
        { id: 3, icon: <FaAnchor />, count: 761, label: "Number of issuers" },
        { id: 4, icon: <FaUser />, count: 564, label: "Total number of unique ISIN listed" },
    ];





    const styles = {
        app: {
            fontFamily: 'Arial, sans-serif',
            textAlign: 'center',
            paddingTop: '50px',
            color: '#333',
            backgroundColor: '#efecfe'
        },
        title: {
            fontSize: '2rem',
            color: '#444',
            marginBottom: '30px',
        },
        statsContainer: {
            display: 'flex',
            justifyContent: 'center',
            gap: '40px',
            marginTop: '20px',
            marginBottom: '35px'
        },
        statItem: {
            fontSize: '1.1rem',
            fontWeight: 'bold',
            animation: 'blinkAnimation 2s infinite',
            animationTimingFunction: 'ease-in-out',
        },
        statLabel: {
            fontSize: '1.1rem',
            color: '#666',
            marginTop: '5px',

        },
        pageHeading: {
            fontWeight: '800',
            fontSize: '30px',
        },

        carouselContainer: {
            textAlign: 'center',
            padding: '20px',
        },
        heading: {
            fontSize: '24px',
            color: '#333',
            borderBottom: '3px solid #f5a623',
            display: 'inline-block',
            marginBottom: '20px',
        },
        carousel: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
        },
        arrowButton: {
            fontSize: '24px',
            padding: '10px 15px',
            cursor: 'pointer',
        },
        pauseResumeButton: {
            fontSize: '16px',
            padding: '8px 15px',
            cursor: 'pointer',
            backgroundColor: '#f5a623',
            border: 'none',
            borderRadius: '5px',
            color: 'white',
        },
        imageContainer: {
            width: '300px',
            height: '150px',
            overflow: 'hidden',
            marginTop: '15px',
        },
        image: {
            width: '100%',
            height: 'auto',
            transition: 'transform 0.5s ease',
        },

    };


    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);


    //number animation
    const [number, setNumber] = useState(0);
    const [target, setTarget] = useState(0);
    const duration = 2000;

    const bondsData = []


    // setTarget(jsonData.length)

    // const numberAnimation = async (target) => {
    //       const startTime = performance.now();
    //       const animate = (currentTime) => {
    //           const elapsed = currentTime - startTime;
    //           const progress = Math.min(elapsed / duration, 1);
    //           const newValue = Math.floor(progress * target);

    //           setNumber(newValue);
    //           if (progress < 1) {
    //               requestAnimationFrame(animate);
    //           }
    //       }
    //       requestAnimationFrame(animate);
    //   }


    return (
        <>
            <div class="col-md-12">
                <header
                    class="MuiPaper-root MuiAppBar-root MuiAppBar-positionRelative MuiAppBar-colorPrimary StaticPage-appBar-2 StaticPage-appBarShift-3 MuiPaper-elevation4">

                </header>
                <div class="StaticPage-content-9 StaticPage-contentShift-10">
                    {/* <hr class="mt-2 hide"></hr><span></span> */}
                    <div class="row">
                        <div class="col-md-12">
                            <div class="static-para">
                                <br></br>
                                <br></br>
                                <div class="notes-section">
                                <h3 className="notes-title">Key highlights - Debt Capital Markets NSEIX</h3>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                <p>Number of Issuers : 55</p>
                <p>Number of MTN : 55</p>
                <p>Total MTN Size (USD Mn) : 55</p>
                <p>Number of unique ISIN listed : 55</p>
                <p>Total Listing (USD Mn) : 55</p>
            </div> */}
                        {/* <div class="col-md-12 pt-2 mb-2">
                <div class="notes-section">
                    <h3 class="notes-title">Benefits of listing on NSE IFSC</h3>
                </div>
            </div> */}
                        <div></div>


                        <div>
                            {/* <h1 style={styles.title}>Our Statistics</h1> */}
                            <div className="col-md-12 bond-container" >
                                <div className="col-md-2 mb-2" >
                                    <div className="total-bond">US ${totalBondsValue} Mn </div>
                                    <div className="bond-text"  >Total Listed Bonds</div>
                                    </div>
                                 

                                <div className="col-md-2 mb-2 " >
                                   
                                 <div className="total-outstanding">US ${totalOutstandingBondsValue} Mn</div>
                                   <div  className="bond-text" >Outstanding Listed Bonds</div>
                                    </div>
                                    
                                <div className="col-md-2 mb-2">
                                    
                                  <div className="total-esg"> US ${esgLableBonds} Mn</div> 
                                  <div className="bond-text" >Value of ESG Labelled Bonds</div>
                                   </div>
                                  
                                <div className="col-md-2 mb-2" >
                                    
                                 <div className="total-numberofissuer">{numberOfIssuer}</div>
                                 <div className="bond-text" >Number of Issuers</div>
                               
                                    </div>
                                <div className="col-md-2 mb-2" >
                                <div className="uniqueisin">{uniqueIsin}</div>
                                    <div  className="bond-text" >Total No. of Bonds Listed</div>
                               
                                    
                                    </div>
                            </div>
                            <style>
                                {`
          /* CSS for Blinking Animation */
          @keyframes blinkAnimation {
            0%, 100% { color: #333; }       /* Default color */
            25% { color: #FF5733; }         /* Bright orange */
            50% { color: #33FF57; }         /* Bright green */
            75% { color: #3357FF; }         /* Bright blue */
          }
         
          .blink {
            animation: blinkAnimation 2s infinite;
          }
        `}
                            </style>
                        </div>
                        <div className="col-md-12">
                            <div className="responsive-static-table static-table" id="issuerData">
                                <table className="equal-table" border="1" cellPadding="1" cellSpacing="1">
                                    <thead>
                                        <tr>
                                            <th scope="col">Total MTN</th>
                                            <th scope="col">DEBT listing under MTN</th>
                                            <th scope="col">DEBT listing under Standalone</th>
                                           {/* <th scope="col">GSS bonds</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="mnt-button" onClick={()=> handleMtn()}>US ${mtnInUSD} Mn</td>
                                            <td >US ${debtListingUnderMtnInUSD} Mn</td>
                                            <td >US ${debtListingUnderStandaloneInUSD} Mn</td>
                                            {/*<td >US ${gssBondsInUSD} Mn</td> */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            isExpanded ?
                            <div className="col-md-12">
                            <div className="responsive-static-table static-table" id="issuerData">
                                <table border="1" cellPadding="1" cellSpacing="1">
                                    <thead>
                                        <tr>
                                            <th scope="col">Issuer Name</th>
                                            <th scope="col">Programme Size In USB</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            mtnData != undefined && mtnData.length > 0 && mtnData.map((value, index) =>
                                                <tr>
                                                    <td key={index}>{value.ISSUER} </td>
                                                    <td key={index}>{value.PROGRAMME_SIZE_IN_USD} </td>
                                                </tr>
                                            )
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>: ''
                        }
                        



                        <div class="col-md-12">
                            <div class="static-para">
                                <p>Listing on NSE IX provides an efficient international listing process to issuers with minimum turnaround time and investment opportunities for investors from across the world.</p>
                                {/* <p>Debt Securities Market platform provides an efficient international listing process to issuers
                                    with minimum turnaround time and investment opportunities for investors from across the world.
                                </p> */}
                            </div>
                        </div>
                        <div class="col-md-12 pt-2 mb-2">
                            <div class="notes-section">
                                <h3 class="notes-title">Benefits of listing on NSE IFSC</h3>
                            </div>
                        </div>
                        <div class="col-md-3 mb-2">
                            <div class="issueinfo-card">
                                <div class="issue-img"><img
                                    src="https://www.nseix.com/nseixcms/sites/default/files/2022-01/Capture1_5.PNG"
                                    alt="Capture1" /></div>
                                <p>Lower rate of 4% on with holding tax on interest paid on debt securities</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-2">
                            <div class="issueinfo-card">
                                <div class="issue-img"><img
                                    src="https://www.nseix.com/nseixcms/sites/default/files/2022-01/Capture_2.PNG"
                                    alt="Zero Listing &amp; Admission fees" /></div>
                                <p>Competitive Listing Fees</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-2">
                            <div class="issueinfo-card">
                                <div class="issue-img"><img
                                    src="https://www.nseix.com/nseixcms/sites/default/files/2022-01/Capture2_2.PNG"
                                    alt="Efficient Listing Process" /></div>
                                <p>Efficient Listing Process</p>
                            </div>
                        </div>
                        <div class="col-md-3 mb-2">
                            <div class="issueinfo-card">
                                <div class="issue-img"><img
                                    src="https://www.nseix.com/nseixcms/sites/default/files/2022-01/Capture3_2.PNG"
                                    alt="capture" /> </div>
                                <p>Simple &amp; Transparent Disclosures &amp; Obligations</p>
                            </div>
                        </div>
                        <div class="col-md-12 pt-2 mb-2">
                            <div class="notes-section">
                                <h3 class="notes-title">Our Offering</h3>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="static-para">
                                <p>List your Foreign Currency Bonds, ESG Compliant Bonds and Masala Bonds on NSE IFSC’s Debt
                                    Securities Market.</p>
                            </div>
                        </div>
                        <div class="col-md-4 mb-2"><a href="/listing/debt/foreign-currency-bonds">
                            <div class="offer-card">
                                <h3 class="issue-title">Foreign Currency Bonds</h3>
                            </div>
                        </a></div>
                        <div class="col-md-4 mb-2"><a href="/listing/debt/isx-bonds">
                            <div class="offer-card">
                                <h3 class="issue-title">ESG Bonds</h3>
                            </div>
                        </a></div>
                        <div class="col-md-4 mb-2"><a href="/listing/debt/masala-bonds">
                            <div class="offer-card">
                                <h3 class="issue-title">Masala Bonds</h3>
                            </div>
                        </a></div>
                        <div class="col-md-12 pt-2 mb-2">
                            <div class="notes-section">
                                <h3 class="notes-title">Issuers on Our Platforms</h3>
                            </div>
                        </div>
                        <div className="container-fluid light-grey-bg">
                            <section id="IssuerImageSlider" className="market-banner-slides">
                                <div className="row">
                                    <div className="columns">
                                        {<CarouselProvider className="rating-slider"
                                            naturalSlideWidth={100}
                                            naturalSlideHeight={30}
                                            totalSlides={10}
                                            visibleSlides={6}
                                            isPlaying={images.length > visibleSlide ? true : false}
                                            interval={3000}
                                        >
                                            <Slider aria-label="market-ticker" className="imgSlider">
                                                {images.length > 0 && images.map((event, index) => {
                                                    return <Slide id={"market-slider-" + index} key={index} width="auto" className='mr-1 ml-1'>
                                                        <img id={event.alt} src={event.src} alt={event.alt} height='80px' width="auto" className="imgSlide" />
                                                    </Slide>
                                                })}
                                            </Slider>
                                            <div className="slider-control-buttons">
                                                <ButtonBack className="prev arrow-btn-slider"><ArrowBackIosIcon className="next-prev" /></ButtonBack>
                                                <ButtonPlay className="play-pause arrow-btn-slider" childrenPaused={<PlayIcon className="next-prev" />} childrenPlaying={<PauseIcon className="next-prev" />} />
                                                <ButtonNext className="next arrow-btn-slider"><ArrowForwardIosIcon className="next-prev" /></ButtonNext>
                                            </div>
                                        </CarouselProvider>}
                                    </div>

                                </div>

                            </section>
                        </div>
                    </div>
                    <div class="scroll-to-top"></div>
                </div>
            </div>

        </>
    );
}

export default DebtListing;
