import { React, useEffect, useState } from "react";
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { getToggleHeader, getToggleVal, convertNumberPrecision } from "../util";


function TbgTableGlobalStocksHomepage(props) {
    const globalStocksDataColumn = props.columnData;
    const dataRows = props.apiData;
    const state3tog = props.toggleData;
    const page = props.page;
    const rowsPerPage = props.rowsPerPage
    return (
        <>
            <div className="col-md-12 tbgtbl">
                <div className="table-responsive tbgtable" id="responsiveTable">
                    <Table id="businessGrowthTbl" className="table lefthd-table">
                        <TableHead>
                            <TableRow key="time">
                                {globalStocksDataColumn.map((item, idx) => {
                                    return item.label.columns ? (
                                        <th
                                            className="MuiTableCell-head"
                                            key={`${idx}${item.label.label}`}
                                            colSpan="2"
                                            scope="col"
                                        >
                                            {item.label.label}
                                        </th>
                                    ) : (
                                        <th
                                            className="MuiTableCell-head"
                                            key={`${idx}${item.label}`}
                                            rowSpan="2"
                                            scope="col"
                                        >
                                            {item.label}
                                        </th>
                                    );
                                })}
                            </TableRow>
                            <TableRow key="data">
                                {globalStocksDataColumn.map((item, idx) => {
                                    return item.label.columns ? (
                                        Object.keys(item.label.columns).map((subitem, colidx) => {
                                            return (
                                                <th
                                                    className={"MuiTableCell-head w-9 text-right"}
                                                    key={`${idx}${colidx}`}
                                                    scope="col"
                                                >
                                                    {item.label.columns[subitem]}
                                                    <br />
                                                    {item.label.columns[subitem].includes("Val")
                                                        ?
                                                        getToggleHeader(state3tog)

                                                        : ""}
                                                </th>
                                            );
                                        })
                                    ) : (
                                        <>
                                        </>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dataRows.length > 0 ?
                                dataRows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage +
                                    rowsPerPage
                                ).map((item, index) => {
                                        return (
                                            <TableRow key={`${index}-global`} >
                                                <TableCell >{item.DATE}</TableCell>
                                                <TableCell >{item.USAIDR_Volume ? item.USAIDR_Volume : 0}</TableCell>
                                                <TableCell >{convertNumberPrecision(getToggleVal(item.USAIDR_Value ? item.USAIDR_Value : 0, state3tog))}</TableCell>
                                                <TableCell >{item.Total_Volume ? item.Total_Volume : 0}</TableCell>
                                                <TableCell >{convertNumberPrecision(getToggleVal(item.Total_Value ? item.Total_Value : 0, state3tog))}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow >
                                        <TableCell className="no-records" colSpan={5}>
                    No Records
                  </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
            </div>

        </>)
}
export default TbgTableGlobalStocksHomepage;