import React from 'react';
import NavTabs from './components/nav-tabs';
import MostActiveDataTable from './components/most-active-data-table';
//import env from '../config';;
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Select, MenuItem } from "@material-ui/core";
// import SearchIcon from '@material-ui/icons/Search';
import Notes from './components/notes';


class MostActive extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            //   headerRef: props.headerRef,
            equitySortBy: 'Volume',
            comSortBy: 'Volume',
            curSortBy: 'Volume',
            ddEquity: [
                {
                    id: 1,
                    text: 'Most Active Contracts',
                    param1: '',
                    param2: '',
                },
                {
                    id: 2,
                    text: 'Most Active Future Contracts',
                    param1: 'FUT',
                    param2: '',
                },
                {
                    id: 3,
                    text: 'Most Active Option Contracts',
                    param1: 'OPT',
                    param2: '',
                },
                {
                    id: 4,
                    text: 'Most Active Call',
                    param1: '',
                    param2: 'CE',
                },
                {
                    id: 5,
                    text: ' Most Active Pull',
                    param1: '',
                    param2: 'PE',
                },
                {
                    id: 6,
                    text: 'Most Active Contracts by OI',
                    param1: '',
                    param2: '',
                    sortBy:'oi'
                },

            ],
            equityValue: '',
            search: '',
            notesApi: `/api/contents/notes?url=/most-active`,
            eqnotesData: [],
            comnotesData: [],
            curnotesData: [],
            
        }
       
        this.fetchEquityValue = this.fetchEquityValue.bind(this);
        this.fetchCurValue = this.fetchCurValue.bind(this);
        this.fetchComValue = this.fetchComValue.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.fetchEquityValue(this.state.equitySortBy, '', '');
            this.fetchComValue(this.state.comSortBy, '', '');
            this.fetchCurValue(this.state.curSortBy, '', '');
            this.loadNotes(this.state.notesApi);
            //this.state.headerRef.current.updateTitle("Most Active", "Most Active");
            this.setState({ equityValue: this.state.ddEquity[0] });
        } catch (e) {
            console.log(e);
        }

    }

    fetchEquityValue(sort, param1, param2) {
        if (sort === 'Volume') {
            this.setState({ equityApiUrl: `/api/most-active-equity?inst_type1=IDX&inst_type2=STK&inst_type3=CBI&sortBy=volume&instrumentparam3=` + param1 + `&optiontypeparam4=` + param2 });
        } else if (sort === 'Value') {
            this.setState({ equityApiUrl: `/api/most-active-equity?inst_type1=IDX&inst_type2=STK&inst_type3=CBI&sortBy=turnover&instrumentparam3=` + param1 + `&optiontypeparam4=` + param2 });
        }else
        {
            this.setState({ equityApiUrl: `/api/most-active-equity?inst_type1=IDX&inst_type2=STK&inst_type3=CBI&sortBy=` + sort + `&instrumentparam3=` + param1 + `&optiontypeparam4=` + param2 });
        }
    }
    fetchCurValue(sort, param1, param2) {
        if (sort === 'Volume') {
            this.setState({ curApiUrl: `/api/most-active-cur?inst_type1=CUR&sortBy=volume&instrumentparam3=` + param1 + `&optiontypeparam4=` + param2 });
        } else if (sort === 'Value') {
            this.setState({ curApiUrl: `/api/most-active-cur?inst_type1=CUR&sortBy=turnover&instrumentparam3=` + param1 + `&optiontypeparam4=` + param2 });
        }else
        {
            this.setState({ curApiUrl: `/api/most-active-cur?inst_type1=CUR&sortBy=` + sort + `&instrumentparam3=` + param1 + `&optiontypeparam4=` + param2 });
        }
    }
    fetchComValue(sort, param1, param2) {
        if (sort === 'Volume') {
            this.setState({ comApiUrl: `/api/most-active-com?inst_type1=COM&sortBy=volume&instrumentparam3=` + param1 + `&optiontypeparam4=` + param2 });
        } else if (sort === 'Value') {
            this.setState({ comApiUrl: `/api/most-active-com?inst_type1=COM&sortBy=turnover&instrumentparam3=` + param1 + `&optiontypeparam4=` + param2 });
        }
    }

    loadNotes(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                let eqnotesData = [];
                let comnotesData = [];
                let curnotesData = [];
                let title = cards.content.field_note_components[0];
                eqnotesData.push(title,cards.content.field_note_components[1]);
                comnotesData.push(title,cards.content.field_note_components[3])
                curnotesData.push(title,cards.content.field_note_components[2])
                this.setState({
                    eqnotesData,comnotesData,curnotesData
                })
            })
            .catch((e) => { console.log("error in notes", e) })
    }

    render() {
        return (<div className="container-fluid p-1"> {/* Used to give padding to the entire view */}
            <NavTabs
                tabs={[
                    {
                        title: "Equity",
                        component: <>
                        <div className="container-fluid  mt-2">
                            <div className="row align-items-center radiobtn">

                                <div className="col-md-4">
                                    <Select className="customize-input-group quotes-select-filter p-1 mb-3" /* Used to give padding between the filter and the radio buttons*/
                                        value={this.state.equityValue}
                                        onChange={(e) => {
                                            this.setState({ equityValue: e.target.value });
                                            if(e.target.value.sortBy !== 'undefined' )
                                            {
                                                this.fetchEquityValue(e.target.value.sortBy, e.target.value.param1, e.target.value.param2);
                                            }
                                            else{
                                            this.fetchEquityValue(this.state.equitySortBy, e.target.value.param1, e.target.value.param2);
                                            }
                                        }}
                                    >

                                        {this.state.ddEquity.map((x, idx) => {
                                            return <MenuItem key={x.id + idx} value={x}>
                                                {x.text}
                                            </MenuItem>
                                        })}

                                    </Select>
                                </div>
                                <div className="col-xs-1 pl-2 pr-0">
                                <FormLabel  component="legend" className="sort-equity">Sort By</FormLabel>
                                </div>
                                <div className="col-xs-6 pl-0">
                                    {/* radio button  */}
                                   
                                    <FormControl component="fieldset">
                                        <div className="radioButton">
                                          
                                            <RadioGroup aria-label="Sort By" name="Sort By"
                                                value={this.state.equitySortBy}
                                                onChange={(e) => {
                                                    this.setState({ equitySortBy: e.target.value });
                                                    this.fetchEquityValue(e.target.value, this.state.equityValue.param1, this.state.equityValue.param2);

                                                }}>
                                                <FormControlLabel 
                                                className={"equity-vol-fil " + (this.state.equitySortBy === 'Volume' ? "selected-sort-radio" : "" )} 
                                                value={'Volume'} control={<Radio />} label={'Volume'} key={0} />
                                                <FormControlLabel 
                                                 className={" equity-val-fil " + (this.state.equitySortBy === 'Value' ? "selected-sort-radio" : "" )} 
                                                 value={'Value'} control={<Radio />} label={'Value'} key={1} />
                                            </RadioGroup>
                                        </div>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row">
                            <MostActiveDataTable api={this.state.equityApiUrl} searchKey={this.state.search} />
                            <div className="col-md-12">
                                <Notes notesData={this.state.eqnotesData} />
                            </div>
                            </div>
                            </div>

                        </>
                    },
                    {
                        title: "Currency", component: <>
                         <div className="container-fluid  mt-2">
                            <div className="row align-items-center radiobtn">
                                <div className="col-md-4">
                                    <Select className="customize-input-group quotes-select-filter p-1 mb-3" /* Used to give padding between the filter and the radio buttons*/
                                        value={this.state.equityValue}
                                        onChange={(e) =>  {
                                            this.setState({ equityValue: e.target.value });
                                            if(e.target.value.sortBy !== 'undefined' )
                                            {
                                                this.fetchCurValue(e.target.value.sortBy, e.target.value.param1, e.target.value.param2);
                                            }
                                            else{
                                            this.fetchCurValue(this.state.curSortBy, e.target.value.param1, e.target.value.param2);
                                            }
                                        }}>
                                        {this.state.ddEquity.map((x, idx) => {
                                            return <MenuItem key={x.id + idx} value={x}>
                                                {x.text}
                                            </MenuItem>
                                        })}

                                    </Select>
                                </div>
                                <div className="col-xs-1 pl-2 pr-0">
                                <FormLabel  component="legend" className="sort-equity">Sort By</FormLabel>
                                </div>
                                <div className="col-xs-6 pl-0">
                                    <FormControl component="fieldset">
                                        <div className="radioButton">
                                            {/* <FormLabel component="legend" className="col-lg-3 mt-0 sort-equity">Sort By</FormLabel> */}
                                            <RadioGroup aria-label="Sort By" name="Sort By"
                                                value={this.state.curSortBy}
                                                onChange={(e) => {
                                                    this.setState({ curSortBy: e.target.value });
                                                    this.fetchCurValue(e.target.value, this.state.equityValue.param1, this.state.equityValue.param2);
                                                }}
                                            >
                                                <FormControlLabel  className={"equity-vol-fil " + (this.state.curSortBy === 'Volume' ? "selected-sort-radio" : "" )} value={'Volume'} control={<Radio />} label={'Volume'} key={0} />
                                                <FormControlLabel  className={" equity-val-fil " + (this.state.curSortBy === 'Value' ? "selected-sort-radio" : "" )} value={'Value'} control={<Radio />} label={'Value'} key={1} />
                                            </RadioGroup>
                                        </div>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row">
                            <MostActiveDataTable api={this.state.curApiUrl} />
                            <div className="col-md-12">
                                <Notes notesData={this.state.curnotesData} />
                            </div>
                            </div>
                            </div>
                        </>
                    },
//                    {
//                        title: "Commodity", component: <>
//                         <div className="container-fluid  mt-2">
//                         <div className="row align-items-center radiobtn">
//
                    //     <div className="col-xs-1 pl-4 pr-0">
                    //            <FormLabel component="legend" className="sort-equity">Sort By</FormLabel>
                    //            </div>
                    //            <div className="col-xs-6 pl-0">
                    //               {/* radio button */}
                    //                <FormControl component="fieldset">
                    //                    <div className="radioButton row align-items-center">
                    //                        {/* <FormLabel component="legend" className="col-lg-3 mt-0 sort-equity">Sort By</FormLabel> */}
                    //                        <RadioGroup aria-label="Sort By" name="Sort By"
                    //                            value={this.state.comSortBy}
                    //                            onChange={(e) => {
                    //                                this.setState({ comSortBy: e.target.value });
                    //                                this.fetchComValue(e.target.value, this.state.equityValue.param1, this.state.equityValue.param2);
                    //                            }}
                    //                        >
                    //                            <FormControlLabel   className="equity-vol-fil" value={'Volume'} control={<Radio />} label={'Volume'} key={0} />
                    //                            <FormControlLabel   className=" equity-val-fil" value={'Value'} control={<Radio />} label={'Value'} key={1} />
                    //                        </RadioGroup>
                     //                   </div>
                     //               </FormControl>
                     //           </div>
                     //       </div>
                     //       <div className="row">
                     //       <MostActiveDataTable api={this.state.comApiUrl} />
                     //       </div>
                     //       {/* <div className="col-md-12">
                     //          <Notes notesData={this.state.comnotesData} />
                     //       </div>
                     //   </div>
                     //   </>
                    //},
                ]} />
        </div>
        )
    }
}
export default MostActive;
