import React from "react";
import X from "../../public/images/x.jpg";
import Linkdin from "../../public/images/LinkedIn_logo.jpg";
import WIW from "../../public/images/WIW_Logo.jpg";
import "../../App.css";
// import logo1  from "../../public/images/wiw1.png";
// import logo2 from "../../public/images/wiw2.JPG";
import logo1 from "../../public/images/Bell.jpg";
import logo2 from "../../public/images/ESG-Webinar.png";
// import logo2 from "../../public/images/Brochure-launch.jfif";

function WorldInvestorPage() {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header" style={{ backgroundColor: '#389aff', display:'flex', justifyContent:'space-between'}}>
                        <h3 style={{ color: 'white', fontWeight: '600', fontSize: '40px', fontFamily:'Futura' , margin:'20px 0' }}>What is World Investor Week?</h3>
                        <div className="logo-container">
                        <span>
                        <a href="https://www.linkedin.com/company/nseix" target="_blank" rel="noopener noreferrer" style={{paddingRight:'20px'}}>
                            
                            <img src={Linkdin} alt="linkdin logo" width="35" height="35" style={{borderRadius:'5px'}} />
                        </a>
                       
                      <a href="https://x.com/nse_ix" target="_blank" rel="noopener noreferrer" style={{paddingRight:'20px'}}>
                      <img src={X} alt="X logo" width="35" height="35" style={{borderRadius:'25px'}} />
                      </a>
                      <a href="https://www.worldinvestorweek.org/" target="_blank" rel="noopener noreferrer">
                        <img className="wiw-logo" src={WIW} alt="wiw logo " width="200" height="100"/>
                        </a>
                      </span>
                      </div>
                    </div>
                    <div className="card-body" style={{ fontWeight: '600', fontSize: '30px', fontFamily:'Futura' }}>
                        <p>
                            World Investor Week (WIW) is a week-long, global campaign promoted by IOSCO to raise awareness about the importance of
                            investor education and protection and highlight the various initiatives of securities regulators in these two critical areas.
                        </p>
                        <p>
                           WIW offers a unique opportunity to IOSCO members to demonstrate their commitment to protecting investors and promoting fair and efficient markets.                    
                       </p>
                    </div>
                    <div className="row">
                        <div className="col-md-6" style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                            <div className="image-wiw-logo">
                                <img src={logo2} alt="image"  />
                            </div>
                        </div>
                        <div className="col-md-6" style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                            <div className="image-wiw-logo">
                                <img src={logo1} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default WorldInvestorPage;
