import React, { useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from "@material-ui/core";
import { TableCell } from '@material-ui/core';
import { ViewColumnIcon, DownloadIcon, Refresh, State3toggle } from "./custom-toolbar";
import CustomizeColumns from './customize-columns';
// import moment from 'moment';
import { convertPrice, convertNumberPrecision, getToggleVal, getToggleHeader } from './util';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import CustomMuiTablePagination from './custom-muitable-pagination';
const breakpoints = createBreakpoints({})

class DerivativesDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
        this.state = {
            shouldAutoRefresh: true,
            showPrompt:true,
            api: props.api,
            statusApi: `/api/derivatives-market-status`,
            marketOpen: '',
            insttype: props.insttype,
            columns: [],
            data: [],
            noMatchLabel: "Loading Data...",
            state3tog: "Millions",
            total: [],
            sortedCol: "CONTRACTSTRADED",
            sortDir: "desc",
            rowsPerPage: 10,
            tabname: props.tabname || "equityderivatives",
            mobileScreen:window.innerWidth <= 640,
            options: {
                selectableRows: "none",
                viewColumns: props.viewcols,
                download: props.csv,
                filter: false,
                print: false,
                search: false,
                elevation: 0,
                responsive: 'vertical',
                // tableBodyMaxHeight: '500px',
                downloadOptions: {
                    filename: props.filename + '.csv'
                },
                customToolbar: () => {
                    return (
                        <>
                            <State3toggle toggleCallback={this.toggle3Switch} defaultState={"Millions"} />
                            {props.viewbutton ? <a target="_blank" rel="noopener noreferrer" aria-label="View Derivatives Watch Page" href={"/markets/derivatives-watch#" + this.state.tabname} style={{ order: `3` }} className="ml-2 btn btn-orange pr-4 pl-4">View All</a> : ""}
                        </>
                    );
                },
            },
            components: {
                icons: {
                    DownloadIcon,
                    ViewColumnIcon,
                }
            },
        };
        this.refresh = this.refresh.bind(this);
        this.toggle3Switch = this.toggle3Switch.bind(this);
        this.updateColumnsTable = this.updateColumnsTable.bind(this);
    };
    toggle3Switch(checked) {
        this.setState({ state3tog: checked });
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                
                actions: {
                    display: "flex",
                    flex: "initial",
                    alignItems: `center`,
                    // move all icons to the right
                    // "& > span, & > button": {
                    //   order: 99
                    // },
                    // target the custom toolbar icon
                    "& > span:last-child, & > button:last-child": {
                        order: 1
                    },
                    // target any icon
                    "& > span:nth-child(1), & > button:nth-child(1)": {
                        order: 3,
                        border: '1px solid #e86e25',
                        borderRadius: '50%',
                        padding: '5px',
                        height: '40px',
                        width: '40px',
                        [breakpoints.down('xs')]: {
                            marginLeft:'10px',
                        }
                        // marginTop: '2%',
                    },
                    "& > span:nth-child(2), & > button:nth-child(2)": {
                        order: 2
                    },
                    [breakpoints.down('xs')]: {
                        display: 'flex',
                        textAlign: `left !important`,
                        flexWrap:'wrap',
                        marginTop:'10px',
                    }
                },
                root:{
                    paddingLeft: "0",
                    paddingRight: "0",
                }
            },
            // MuiToolbar: {
            //     root: {
            //         paddingLeft: "0 !important",
            //         paddingRight: "0 !important",
            //     }
            // },
            MuiTooltip: {
                tooltip: {
                    fontSize: "12px",
                }
            },

        }
    })


    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadData(this.state.api);
            this.loadMarketStatus(this.state.statusApi);
            window.addEventListener("resize", this.resize.bind(this));
            this.resize();
            if(!this.state.shouldAutoRefresh){
                //console.log("the value of normal refresh if", this.state.shouldAutoRefresh)
                clearInterval(this.interval);
               // this.stopTimer();
            }else{
                this.interval = setInterval(()=> {
                    this.refresh();
                  //  console.log("the value of normal refresh is", this.state.shouldAutoRefresh);
                }, 30000);
            }
           
            


            //Prompt after every 5 minutes :
            // const showPrompt = localStorage.getItem('showPrompt');

            if(this.state.showPrompt){
                this.timer = setTimeout(()=>{
                    const userResponse = window.confirm("Do you want to refresh the live watch content?");
                    if(userResponse){
                        window.location.reload();
                        // this.refresh();
                        this.interval = setInterval(()=> {
                            this.refresh();
                        }, 30000)
                    }else{
                        this.state.shouldAutoRefresh = false;
                        this.state.showPrompt=false;
                        console.log("the value of auto refresh", this.state.shouldAutoRefresh);
                        clearInterval(this.interval);

                        
                    }
                },300000)
            }

        } catch (e) {
            console.log(e);
        }
    }
    // stopTimer(){
    //     clearInterval(this.interval);
    // }
    resize() {
        let currentmobileScreen = (window.innerWidth <= 640);
        if (currentmobileScreen !== this.state.mobileScreen) {
            this.setState({mobileScreen: currentmobileScreen});
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
        clearInterval(this.interval);
        clearTimeout(this.timer);
      
       
    }
    loadMarketStatus(api) {

        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                this.setState({
                    marketOpen: data.marketstatus,
                })
            })

    }

    componentDidUpdate(prevProps) {
        if (this.props.api !== prevProps.api) {
            this.setState({ api: this.props.api },
                () => {
                    this.loadData(this.state.api);
                });
        }
    }
    updateColumnsTable(cols) {

        //const offsetWidth = this.wrapper.current.offsetWidth || 1100;
        let colsList = [];
        colsList = cols.map((col) => {
            //col.width = offsetWidth * (col.width / 100);
            col = {

                label: col.tooltip || col.headerName,
                name: col.field,
                colorIndicator: col.colorIndicator,
                headerName: col.headerName,
                subHeader: col.subHeader,
                bold: col.bold,
                type: col.type,
                decimal: col.decimal,
                precision: col.precision,
                width: col.width || `auto`,
                options:
                {
                    customHeadRender: ({ index, ...column }, sortColumn, sortOrder) => {

                        if (col.name === "TURNOVER" || col.name === "PREMIUMTURNOVER") {
                            col.subHeader = getToggleHeader(this.state.state3tog);
                            // if (this.state.state3tog === "left") {
                            //     col.subHeader = "($ Thousands)";
                            // }
                            // else if (this.state.state3tog === "center") {
                            //     col.subHeader = "($ Millions)";
                            // }
                            // else {
                            //     col.subHeader = "($ Billions)";
                            // }
                        }
                        return (
                            <Tooltip title={col.label} placement="bottom" key={col.name}>
                                <TableCell className={col.name === sortOrder.name && column.sortDirection !== null ? "sorted-col" : "unsorted-col"}
                                    align={col.type === "number" ? "right" : (col.type === "dateTime" ? "center" : "left")}
                                    style={{ width: col.width }}
                                    onClick={() => sortColumn(index)} key={index}>
                                    <span className={"preline-span " + (col.name === sortOrder.name && column.sortDirection !== null ? "sortactive" : "")}>
                                        {col.headerName.split(" ")[0]}<br />
                                        {col.headerName.split(" ")[1]}
                                        {col.subHeader ? <span className="dg-table-sub-header" >
                                            {col.subHeader && ` ${col.subHeader}`}
                                        </span> : ""}


                                    </span>
                                    <span className="sorting-icons" aria-label={"Sort By " + col.label}>
                                        {col.name === sortOrder.name && column.sortDirection !== null ?
                                            (sortOrder.direction === "asc" ? <i className="fa fa-arrow-up"></i>
                                                : <i className="fa fa-arrow-down"></i>)
                                            :
                                            <i className="fa fa-sort"></i>
                                        }
                                    </span>
                                </TableCell>
                            </Tooltip>
                        );
                    },
                    //hint:col.headerName,
                    // customHeadLabelRender: (params) => {
                    //     if (col.name === "TURNOVER") {
                    //         if (this.state.state3tog === "left") {
                    //             col.subHeader = "($ Thousands)";
                    //         }
                    //         else if (this.state.state3tog === "center") {
                    //             col.subHeader = "($ Millions)";
                    //         }
                    //         else {
                    //             col.subHeader = "($ Billions)";
                    //         }
                    //     }

                    //     return (
                    //         <>
                    //             <span className={"preline-span " + (col.sortorder === "asc" ? "asc" : (col.sortorder === "desc" ? "desc" : ""))} >
                    //                 {col.headerName.split(" ")[0]}<br />
                    //                 {/* <i className="fa fa-sort"></i><br/> */}
                    //                 {col.headerName.split(" ")[1]}
                    //                 {/* {col.headerName}<i className="fa fa-sort"></i><br /> */}
                    //                 {col.subHeader ? <span className="dg-table-sub-header" >
                    //                     {col.subHeader && ` ${col.subHeader}`}
                    //                 </span> : ""}
                    //             </span>
                    //             {/* <span aria-label={col.headerName}></span> */}
                    //         </>
                    //     )

                    // },
                    customBodyRender: (data, dataIndex, rowIndex) => {
                        let precision = 2;

                        if (col.name === "TURNOVER" || col.name === "PREMIUMTURNOVER") {
                            
                            data = getToggleVal(data,this.state.state3tog);
                            // if (this.state.state3tog === "left") {
                            //     data = data / 1000;
                            // }
                            // else if (this.state.state3tog === "center") {
                            //     data = data / 1000000;
                            // }
                            // else {
                            //     data = data / 1000000000;
                            // }
                        }
                        if (col.name === "SYMBOL") {
                            let symboltype = "";
                            let  identifier = '&instrumenttype=' + dataIndex.rowData[0].substr(0, 3) + '&expiry=' + dataIndex.rowData[2] + '&optiontype=' + dataIndex.rowData[3] + '&strikeprice=' + dataIndex.rowData[4] + '&inst=' + dataIndex.rowData[0];
                            switch (this.state.insttype) {
                                case "cbi":
                                    symboltype = "/bonderivatives-getquote";
                                    identifier = '&instrumenttype=' + dataIndex.rowData[0].substr(0, 3) + '&expiry=' + dataIndex.rowData[2] + '&inst=' + dataIndex.rowData[0];
                                    break;
                                case "cur":
                                    symboltype = "/currency-getquote";
                                    break;
                                case "idx":
                                    symboltype = data.includes("NIFTY") ? "/get-quotes/derivatives" : "/get-quotes/equity";
                                    break;
                                case "stk":
                                    symboltype = data.includes("NIFTY") ? "/get-quotes/derivatives" : "/get-quotes/equity";
                                    break;
                                default:
                                    break;
                            }
                            // console.log("####",data)
                            // let identifier = '&instrumenttype=' + dataIndex.rowData[0].substr(0, 3) + '&expiry=' + dataIndex.rowData[2] + '&optiontype=' + dataIndex.rowData[3] + '&strikeprice=' + dataIndex.rowData[4] + '&inst=' + dataIndex.rowData[0];
                            data = <a target="_blank" rel="noopener noreferrer" aria-label={"View get-quotes for" + data + "instrument" + dataIndex.rowData[0] + "option type" + dataIndex.rowData[3] + "expiry" + dataIndex.rowData[2] + "strike price" + dataIndex.rowData[4]} href={symboltype + "?symbol=" + data + identifier} title={data}>{data}</a>
                        }
                        if (col.precision) {
                            if (dataIndex.rowData[1] === "SILVER") {

                                precision = 3;
                            }
                            else if (this.state.insttype === "cur" && dataIndex.rowData[1] !== "INRUSD") {
                                precision = 4;
                            }
                            else {
                                precision = 2;
                            }
                        }
                        else {
                            precision = 2;
                        }
                        return (
                            <span className={col.colorIndicator ? data >= 0 ? "green bold-columns" : "red bold-columns" : col.bold ? "bold-columns" : ""}>
                                {typeof (data) === "number" ? (col.decimal ? convertNumberPrecision(data, precision) : convertPrice(data)) : data}
                            </span>
                        )
                    },


                    display: col.display,
                    sortCompare: (order) => {

                        if (col.type === "dateTime") {
                            return (obj1, obj2) => {
                                const dateA = new Date(obj1.data).getTime();
                                const dateB = new Date(obj2.data).getTime();
                                return (dateA < dateB ? -1 : 1) * (order === "asc" ? 1 : -1);

                            }
                        } 
                        else if (col.type === "number"){
                            return (obj1, obj2) => {
                                // let val1 = obj1.data;
                                // let val2 = obj2.data;
                                let val1 = obj1.data !== "-" ? obj1.data : 0;
                                let val2 = obj2.data !== "-" ? obj2.data : 0;
                                return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1);
                            };
                        }
                        else {
                            return (obj1, obj2) => {
                                let val1 = obj1.data;
                                let val2 = obj2.data;
                                return (val1 < val2 ? -1 : 1) * (order === 'asc' ? 1 : -1);;
                            };
                        }

                    },
                    setCellProps: () => ({
                        align: col.type === "number" ? "right" : (col.type === "dateTime" ? "center" : "left"),
                    }),
                    // setCellHeaderProps: () => ({ })
                },
            }
            return col;
        });
        this.setState({ columns: colsList })
    }
    loadData(api) {

        fetch(api)
            .then(resp => resp.json())
            .then((data) => {
                data.api = api;
                this.setState({ data: data.data, total: data.total });
                this.updateColumnsTable(data.columns);
                if (data.data.length === 0) {
                    this.setState({ noMatchLabel: "No Records Found" })
                }
                else  {
                    this.setState({ noMatchLabel: "" })
                }
            })
            .catch((e) => { console.log("error in loading derivatives watch data", e) })
    }

    refresh() {

        if (this._isMounted) {
            this.setState({
                data: [],
                marketOpen: '',
                sortedCol: "CONTRACTSTRADED",
                sortDir: "desc"
            });
            this.loadData(this.state.api);
            this.loadMarketStatus(this.state.statusApi);
        }
    }

    render() {
        let total = this.state.total;
        const totalVolume = () => {
            return convertPrice(this.state.total[0].VOLUME);
        }
        const totalOi = () => {
            return this.state.total[0].OI;
        }
        const totalValue = () => {
            return convertNumberPrecision(getToggleVal(this.state.total[0].TURNOVER,this.state.state3tog));
            // this.state.state3tog === "center" ? convertNumberPrecision((this.state.total[0].TURNOVER) / 1000000)
            //     : (this.state.state3tog === "left" ? convertNumberPrecision((this.state.total[0].TURNOVER) / 1000) :
            //         convertNumberPrecision((this.state.total[0].TURNOVER / 1000000000)));

        };
       let mobileScreen =  this.state.mobileScreen;
        
        return (
            // <div ref={this.wrapper} 
            // className={"col-md-12 contrast-table " + (this.state.mobileScreen ? "responsive-mui-table" : "deriv-mui-data-table ")} 
            // // id="responsiveTable"
            // >
            <div ref={this.wrapper} 
            className={(this.state.noMatchLabel ? "no-records " : "" )+
            "col-md-12 responsive-muidatatable deriv-mui-data-table contrast-table"} 
            //id="responsiveTable"
            >

                <MuiThemeProvider theme={this.getMuiTheme()}>
                    {
                        this.state.data ?
                            <MUIDataTable
                                title={<div className="muidatatable-timestamp">{this.state.marketOpen} IST {" "}
                                <Refresh refreshcallback={this.refresh} /></div>}
                                columns={this.state.columns}
                                data={this.state.data}
                                options={{
                                    ...this.state.options,
                                    sortOrder: {
                                        name: this.state.sortedCol,
                                        direction: this.state.sortDir,
                                    },
                                    textLabels: {
                                        body: {
                                            noMatch: this.state.noMatchLabel,
                                            columnHeaderTooltip: col => `${col.label}`
                                        }
                                    },
                                    responsive: mobileScreen ? 'vertical' : 'standard',
                                    // tableBodyMaxHeight:!this.state.mobileScreen ? '500px' : 'initial',
                                    customTableBodyFooterRender: function (opts) {
                                        return mobileScreen ? 
                                        (
                                            <TableFooter className="mui-total-row tbl-footer">

                                                    {/* {opts.selectableRows !== 'none' ? <TableCell /> : null}
                                                    {opts.columns.map((col, index) => {
                                                        if (col.display === 'true') { */}

                                                            
                                                                    <TableRow>
                                                                    <TableCell >
                                                                        <div>Total Volume</div>
                                                                        <div>{total[0] ? totalVolume() : 0}</div>
                                                                        
                                                                    </TableCell>
                                                                    </TableRow>
                                                                     <TableRow>
                                                                     <TableCell >
                                                                     <div>Total Value</div>
                                                                        <div>{total[0] ? totalValue() : 0}</div>
                                                                     </TableCell>
                                                                    
                                                                     </TableRow>
                                                                      {/* <TableRow>
                                                                      <TableCell >
                                                                      <div>Total OI</div>
                                                                        <div>{total[0] ? totalOi() : 0}</div>
                                                                      </TableCell>
                                                                      </TableRow> */}
                                                            
                                                        
                                               
                                            </TableFooter>
                                        ) :
                                        (
                                            <TableFooter  className="mui-total-row">

                                                <TableRow>
                                                    {opts.selectableRows !== 'none' ? <TableCell /> : null}
                                                    {opts.columns.map((col, index) => {
                                                        // console.log(col)
                                                        // console.log(`Data: col:${col} index:${index}`)
                                                        if (col.display === 'true') {
                                                            if (index === 0) {
                                                                return (
                                                                    <TableCell key={index} >
                                                                        Total 
                                                                    </TableCell>
                                                                );
                                                            }
                                                            if (col.name === 'CONTRACTSTRADED') {
                                                                return (
                                                                    <TableCell className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-669 MuiTableCell-alignRight" data-colindex="11" data-testid="MuiDataTableBodyCell-11-9" key={index} >
                                                                    <div class="MUIDataTableBodyCell-root-669">
                                                                        {totalVolume()}
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            } else if (col.name === 'TURNOVER') {
                                                                return (
                                                                    <TableCell className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-669 MuiTableCell-alignRight" data-colindex="11" data-testid="MuiDataTableBodyCell-11-9" key={index} >
                                                                        <div class="MUIDataTableBodyCell-root-669">
                                                                        {totalValue()}
                                                                        </div>
                                                                    </TableCell>
                                                                );
                                                            } 
                                                            else if (col.name === 'OPENINTEREST') {
                                                                return (
                                                                    <TableCell className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-669 MuiTableCell-alignRight" data-colindex="11" data-testid="MuiDataTableBodyCell-11-9" key={index} >
                                                                    <div class="MUIDataTableBodyCell-root-669">
                                                                        {totalOi()}
                                                                    </div>
                                                                    </TableCell>
                                                                );
                                                            } 
                                                            else {
                                                                return <TableCell key={index} />;
                                                            }
                                                        }
                                                        return null;
                                                    })}
                                                </TableRow>
                                            </TableFooter>
                                        );
                                    },
                                    onChangeRowsPerPage: (numberOfRows) => {
                                        this.setState({
                                            rowsPerPage: numberOfRows
                                        })
                                    },
                                    page:0,
                                    customFooter: (count,
                                        page,
                                        rowsPerPage,
                                        changeRowsPerPage,
                                        changePage) => {
                                        return <CustomMuiTablePagination
                                            count={count} page={page}
                                            rowsPerPage={rowsPerPage}
                                            changeRowsPerPage={changeRowsPerPage}
                                            changePage={changePage} />
                                    },
                                    onViewColumnsChange: (columncheck, action) => {

                                        let newCols = this.state.columns.slice();
                                        if (action === 'update') {
                                            for (let ii = 0; ii < newCols.length; ii++) {
                                                newCols[ii].options.display = true;
                                            }
                                        }
                                        else {
                                            for (let col = 0; col < newCols.length; col++) {
                                                if (newCols[col].name === columncheck) {
                                                    newCols[col].options.display = action === 'add' ? true : false;
                                                }
                                            }
                                        }

                                        this.setState({ columns: newCols })
                                    },
                                    onColumnSortChange: (newColumnOrder, columnIndex, newPosition) => {
                                        this.setState({ sortedCol: newColumnOrder, sortDir: columnIndex });
                                    }
                                    // onTableChange: (action, state) => {
                                    //     console.log("onTableChange",state.columns);
                                    //   },
                                }
                                }
                                components={{ ...this.state.components, TableViewCol: CustomizeColumns }}
                            /> :
                            <span>loading</span>
                    }
                </MuiThemeProvider>
            </div>
        );
    };
}

export default DerivativesDataTable;
