import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import LinearProgress from '@material-ui/core/LinearProgress'

class Graph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            api: props.api || ``,
            options: {},
            chartType: props.chartType || "",
            name: props.graphName || "",
            plotAxis: props.points || "",
            data1: props.data1 || [],
            data2: props.data2 || [],
            xaxis: props.xaxis || [],
            data:[],
            dataInProgress:true,

        }
    };
    componentDidMount() {
        try {
            if (this.state.api && this.state.api !== "") {
                this.loadData(this.state.api);
            }
        } catch (e) {
            console.log(e);
        }
    }
    loadData(api) {
        this.setState({dataInProgress:true});
	this.setState({options:{}});
        fetch(api)
            .then(resp => resp.json())
	
            .then((data) => {
	console.log("#################",data);
                if (this.state.plotAxis === 3) {
                    this.setState({
                        options: {
                            title: {
                                text: ''
                            },
                            xAxis: { categories: this.state.xaxis },
                            yAxis: [{
                                title: {
                                    text: this.state.data1.name
                                }
                            },
                            {
                                title: {
                                    text: this.state.data2.name
                                },
                                opposite: true
                            }],
                            legend: {
                                shadow: false
                            },
                            tooltip: {
                                shared: true
                            },
                            series: [this.state.data1, this.state.data2]
                        }
                    })
                }
                else if (this.state.plotAxis === 2) {
                    this.setState({
                        options: {
                            chart: {
                                type: this.state.chartType,
                            },
                            // plotOptions: {
                            //     series: {
                            //         fillColor: {
                            //             linearGradient: [0, 50, 0, 300],
                            //             stops: [
                            //                 [0, "#55a4d5"],
                            //                 [1, Highcharts.Color("#55a4d5").setOpacity(0).get('rgba')]
                            //             ]
                            //         },
                            //         color: '#008000'
                            //         // threshold: Math.min(...data) 
                            //     },
                            // },
                            title: {
                                text: ""
                            },
                            yAxis: {
                                title: "",
                                gridLineWidth: 0,
                            },
                            xAxis: {
                                type: 'datetime',
                            },
                            series: [{
                                data: data.data,
                                name: this.state.name,
                                tooltip:{
                                    valueDecimals:2
                                }                                
                            }],
                        },
                        data:data.data
                        
                    })
                }
                this.setState({dataInProgress:false});
            })
            .catch((e) => { console.log("error in header", e) })
    }

    render() {

        return  this.state.dataInProgress === true ? <LinearProgress/> :  this.state.data.length>0 ? <HighchartsReact highcharts={Highcharts} options={this.state.options}/> :  <div className="d-flex justify-content-center mt-3 mb-3">No records</div> 
    }
}

export default Graph;
