import { React, useEffect, useState } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { State3toggle2 } from "./custom-toolbar";
import { convertNumberPrecision, getToggleVal, getToggleHeader } from './util';
import TbgTableEquityHomepage from "./homepage/TbgTableEquityHomepage";
import TbgTableCurrencyHomepage from "./homepage/TbgTableCurrencyHomepage";
import TbgTableCommodityHomepage from "./homepage/TbgTableCommodityHomepage";
import TbgTableGlobalStocksHomepage from "./homepage/TbgTableGlobalStocksHomepage";
import CustomMuiTablePagination from "./custom-muitable-pagination";

const ButtonHeight = {
    height: '87%',
    paddingBottom: '10px',
}

function BusinessGrowthTab(props) {
    const [businessDataFormat, setBusinessDataFormat] = useState([{
        name: 'Year wise',
        value: 'yearly'
    },
    {
        name: 'Month wise',
        value: 'monthly'
    },
    {
        name: 'Date wise',
        value: 'daily'
    }])
    const segmentType = ['EQUITY', 'CURRENCY', 'COMMODITY', 'GLOBAL_STOCKS']
    const defaultValue = 'daily'
    const currentDate = moment(new Date()).format('DD-MMM-YYYY');
    const lastTenDays = new Date();
    const colsApi = "/api/business-growth-columns";
    lastTenDays.setDate(lastTenDays.getDate() - 10)
    const lastTenDaysAgo = moment(lastTenDays).format('DD-MMM-YYYY');
    const [selectedDataFormat, setSelectedDataFormat] = useState(defaultValue);
    const [todaysDate1, setTodaysDate1] = useState(lastTenDaysAgo);
    const [todaysDate2, setTodaysDate2] = useState(currentDate);
    const [error, setError] = useState('');
    const [dataRows, setDataRows] = useState([]);
    const [equityDataColumn, setEquityDataColumn] = useState([]);
    const [commodityDataColumn, setCommodityDataColumn] = useState([]);
    const [currencyDataColumn, setCurrencyDataColumn] = useState([]);
    const [globalStocksDataColumn, setGlobalStocksDataColumn] = useState([]);
    const [state3tog, setState3Tog] = useState("Thousands");
    const [page,setPage] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(10)
    const dateFormat = "DD-MMM-YYYY";
    let filter = [];
    const apiUrl = props.dataapi;
    const urlParts = apiUrl.split('?');
    if (urlParts.length > 1) {
        const params = urlParts[1].split('&');
        for (const param of params) {
            const [key, value] = param.split('=');
            if (segmentType.includes(value)) {
                filter = value[0].toUpperCase() + value.slice(1).toLowerCase();
            } else {
                console.log('value is not include');
            }
        }
    }


    const handleYearChange = (event) => {
        setSelectedDataFormat(event.target.value);
        if (event.target.value == "monthly") {
            let formattedStartDate = '01-' + todaysDate1.substring(3, 6) + '-' + todaysDate1.substring(7);
            let formattedEndDate = '01-' + todaysDate2.substring(3, 6) + '-' + todaysDate2.substring(7);
            setTodaysDate1(formattedStartDate);
            setTodaysDate2(formattedEndDate);
        }
        else if (event.target.value == "yearly") {
            let formattedStartDate = '31-Mar-' + todaysDate1.substring(7);
            let formattedEndDate = '01-Apr-' + todaysDate2.substring(7);
            setTodaysDate1(formattedStartDate);
            setTodaysDate2(formattedEndDate);
        }
        else {
            let formattedStartDate = todaysDate1.substring(0, 6) + '-' + todaysDate1.substring(9)
            let formattedEndDate = todaysDate2.substring(0, 6) + '-' + todaysDate2.substring(9)
            setTodaysDate1(formattedStartDate);
            setTodaysDate2(formattedEndDate);
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
      };

    const handleChangeRowsPerPage = (val) => {
        setRowsPerPage(rowsPerPage + val);
        setPage(0)
      };
    


    const fetchData = async () => {
        try {
            let resp;
            if (selectedDataFormat == "monthly") {
                let formattedStartDate = todaysDate1.substring(1, 6) + '-' + todaysDate1.substring(9)
                let formattedEndDate = todaysDate2.substring(1, 6) + '-' + todaysDate2.substring(9) //example - 01-May-18
                resp = await fetch(apiUrl + `&timeFormat=${selectedDataFormat}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`);
            }
            else if (selectedDataFormat == "yearly") {
                if (moment(todaysDate1, 'DD-MMM-YYYY', true).isValid()) {
                    let formattedStartDate = "31-MAR-" + todaysDate1.substring(9);
                    let nextYear = moment(todaysDate2, 'DD-MMM-YYYY').add(1, 'years').year();
                    let formattedEndDate = '01-Apr-' + nextYear;
                    //example - 01-May-18
                    resp = await fetch(apiUrl + `&timeFormat=${selectedDataFormat}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`);
                }
                else {
                    let formattedStartDate = todaysDate1;
                    let nextYear = moment(todaysDate2, 'DD-MMM-YYYY').add(1, 'years').year();
                    let formattedEndDate = '01-Apr-' + nextYear;
                    //example - 01-May-18
                    resp = await fetch(apiUrl + `&timeFormat=${selectedDataFormat}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`);

                }


            }
            else {
                let formattedStartDate;
                let formattedEndDate;
                if (moment(todaysDate1, 'DD-MMM-YY', true).isValid()) {
                    formattedStartDate = todaysDate1;
                }
                else {
                    formattedStartDate = todaysDate1.substring(0, 6) + '-' + todaysDate1.substring(9)
                }
                if (moment(todaysDate2, 'DD-MMM-YY', true).isValid()) {
                    formattedEndDate = todaysDate2;
                }
                else {
                    formattedEndDate = todaysDate2.substring(0, 6) + '-' + todaysDate2.substring(9)

                }

                //example - 01-May-18
                resp = await fetch(apiUrl + `&timeFormat=${selectedDataFormat}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`);
            }
            const jsonData = await resp.json();
            if (filter === 'Equity') {
                setDataRows(jsonData.data[0].Equity.rows);
            }
            else if (filter === 'Currency') {
                setDataRows(jsonData.data[0].Currency.rows);
            }
            else if (filter === 'Commodity') {
                setDataRows(jsonData.data[0].Commodity.rows);
            }
            else if (filter === 'Global_stocks') {
                setDataRows(jsonData.data[0].GLOBAL_STOCKS.rows);
            }
        } catch (error) {
            console.log('error in fetching data', error);
        };

    }

    const handleValidate = () => {
        if (!todaysDate1 || !todaysDate2) {
            setError('Both "From" and "To" dates are required.');
        } else {
            fetchData();
            setError('');
        }
    }

    const reset = () => {
        setTodaysDate1(lastTenDaysAgo);
        setTodaysDate2(currentDate);
    }
    const toggle3Switch = (checked) => {
        setState3Tog(checked);
    };

    useEffect(() => {
        loadColumns(colsApi);
        let formattedStartDate = lastTenDaysAgo.substring(0, 2) + '-' + lastTenDaysAgo.substring(3, 6) + '-' + lastTenDaysAgo.substring(9)
        let formattedEndDate = currentDate.substring(0, 2) + '-' + currentDate.substring(3, 6) + '-' + currentDate.substring(9)
        fetch(apiUrl + `&timeFormat=${selectedDataFormat}&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`).then((response) => {
            return response.json();
        }).then((resp) => {
            if (filter === 'Equity') {
                // setDataColumn(resp.data[0].Equity.Columns);
                setDataRows(resp.data[0].Equity.rows);
            }
            else if (filter === 'Currency') {
                // setDataColumn(resp.data[0].Currency.Columns);
                setDataRows(resp.data[0].Currency.rows);
            }
            else if (filter === 'Commodity') {
                // setDataColumn(resp.data[0].Commodity.Columns);
                setDataRows(resp.data[0].Commodity.rows);
            }
            else if (filter === 'Global_stocks') {
                // setDataColumn(resp.data[0].GLOBAL_STOCKS.Columns);
                setDataRows(resp.data[0].GLOBAL_STOCKS.rows);
            }
        }).catch(e => {
            console.log('error ', e);
        })
    }, []);

    const loadColumns = (api) => {
        fetch(api)
            .then((resp) => resp.json())
            .then((data) => {
                console.log("Columns value is >>>",data);
                setEquityDataColumn(data.columns.EQUITY);
                setCommodityDataColumn(data.columns.COMMODITY);
                setCurrencyDataColumn(data.columns.CURRENCY);
                setGlobalStocksDataColumn(data.columns.GLOBAL_STOCKS)
            })
            .catch((err) => {
                console.log("error in columns in business growth", err);
            });
    }
    return (
        <div className="row">
            <div className="col-md-2">
                <Select defaultValue={selectedDataFormat}
                    className="customize-input-group quotes-select-filter"
                    onChange={handleYearChange}
                    value={selectedDataFormat}
                >
                    <MenuItem value='' disabled>Select an option</MenuItem>
                    {businessDataFormat && businessDataFormat.map((x, data) => {
                        return (
                            <MenuItem key={x + data} value={x.value}>
                                {x.name}
                            </MenuItem>
                        )
                    })
                    }
                </Select>
            </div>

            {selectedDataFormat.includes('daily') &&
                <>
                    <div className="col-md-2">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline" placeholder="From" format={"dd-MMM-yyyy"} views={["year", "month", "date"]} openTo="date"
                                    margin="normal" id="histDate" maxDate={new Date()}
                                    value={todaysDate1} onChange={(date) => setTodaysDate1(moment(date, dateFormat).format("DD-MMM-YYYY"))}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label": "from_date_button" }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline" placeholder="To" format={"dd-MMM-yyyy"} views={["year", "month", "date"]} openTo="date"
                                    margin="normal" id="histDate" maxDate={new Date()}
                                    value={todaysDate2} onChange={(date) => {
                                        if (todaysDate1 && date < todaysDate1) {
                                            setTodaysDate2(moment(todaysDate1, dateFormat).format("DD-MMM-YYYY"))
                                        } else {
                                            setTodaysDate2(moment(date, dateFormat).format("DD-MMM-YYYY"))
                                        }
                                    }}
                                    minDate={todaysDate1}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label": "to_date_button" }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </>
            }

            {selectedDataFormat.includes('yearly') &&
                <>
                    <div className="col-md-2">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline" placeholder="From" format={"yyyy"} views={["year"]} openTo="year"
                                    margin="normal" id="histDate" maxDate={new Date()}
                                    value={todaysDate1} onChange={(date) => setTodaysDate1('31-MAR-' + moment(date, dateFormat).format("YYYY"))}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label": "from_date_button" }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline" placeholder="To" format={"yyyy"} views={["year"]} openTo="year"
                                    margin="normal" id="histDate" maxDate={new Date()}
                                    value={todaysDate2} onChange={(date) => {
                                        if (todaysDate1 && date < todaysDate1) {
                                            setTodaysDate2('01-APR-' + moment(todaysDate1, dateFormat).format("YYYY"))
                                        } else {
                                            setTodaysDate2('01-APR-' + moment(date, dateFormat).format("YYYY"))
                                        }
                                    }}
                                    minDate={todaysDate1}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label": "to_date_button" }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </>
            }
            {selectedDataFormat.includes('monthly') &&
                <>
                    <div className="col-md-2">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline" placeholder="From" format={"MMM-yyyy"} views={["year", "month"]} openTo="month"
                                    margin="normal" id="histDate" maxDate={new Date()}
                                    value={todaysDate1} onChange={(date) => setTodaysDate1('01-' + moment(date, dateFormat).format("MMM-YYYY"))}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label": "from_date_button" }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="customize-input-group">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline" placeholder="To" format={"MMM-yyyy"} views={["year", "month"]} openTo="month"
                                    margin="normal" id="histDate" maxDate={new Date()}
                                    value={todaysDate2} onChange={(date) => {
                                        if (todaysDate1 && date < todaysDate1) {
                                            setTodaysDate2('01-' + moment(todaysDate1, dateFormat).format("MMM-YYYY"))
                                        } else {
                                            setTodaysDate2('01-' + moment(date, dateFormat).format("MMM-YYYY"))
                                        }
                                    }}
                                    minDate={todaysDate1}
                                    autoOk={true}
                                    KeyboardButtonProps={{ "aria-label": "to_date_button" }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </>
            }
            <div className="col-sm-1 quotes-filter">
                <button className="btn btn-orange full-width" style={{ ButtonHeight }} onClick={handleValidate}>Go</button>
            </div>

            <div className="col-sm-1 quotes-filter">
                <button className="btn btn-orange full-width" style={{ ButtonHeight }} onClick={reset}>Reset</button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <State3toggle2 toggleCallback={toggle3Switch} defaultState={'Thousands'} />
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className='card' style={{ marginTop: '10px' }}>
                <div className='card-body'>
                    <div>
                        <TableContainer component={Paper}>
                            <Table className="table customize-table" >
                                <TableRow>
                                    {filter === 'Equity' &&
                                        <TbgTableEquityHomepage columnData={equityDataColumn} apiData={dataRows} toggleData={state3tog} page={page} rowsPerPage={rowsPerPage} />
                                    }
                                    {filter === 'Currency' &&
                                        <TbgTableCurrencyHomepage columnData={currencyDataColumn} apiData={dataRows} toggleData={state3tog} page={page} rowsPerPage={rowsPerPage} />
                                    }
                                    {filter === 'Commodity' &&
                                        <TbgTableCommodityHomepage columnData={commodityDataColumn} apiData={dataRows} toggleData={state3tog} page={page} rowsPerPage={rowsPerPage} />
                                    }
                                    {filter === 'Global_stocks' &&
                                        <TbgTableGlobalStocksHomepage columnData={globalStocksDataColumn} apiData={dataRows} toggleData={state3tog} page={page} rowsPerPage={rowsPerPage} />
                                    }
                                </TableRow>
                                
                            <CustomMuiTablePagination
                                count={dataRows.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                changeRowsPerPage={handleChangeRowsPerPage}
                                changePage={handleChangePage} />
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>)

}
export default BusinessGrowthTab;