import React, { useState } from "react";
import BusinessGrowthTab from "./components/business-growth-tab";
import NavTabs from './components/nav-tabs';


function BusinessGrowth() {
    return (
        <div className="container-fluid px-0">
            <NavTabs  tabs={[
             { title: "Equity ", component: <BusinessGrowthTab param={'&inst_type1=IDX&inst_type2=STK'} tabkey="equity" dataapi={`/api/homepage-business-growth?segment=EQUITY`} /> },
             { title: "Currency ", component: <BusinessGrowthTab param={'&inst_type1=CUR'} tabkey="currency" dataapi={`/api/homepage-business-growth?segment=CURRENCY`} /> },
             { title: "Commodity ", component: <BusinessGrowthTab param={'&inst_type1=COM'} tabkey="commodity" dataapi={`/api/homepage-business-growth?segment=COMMODITY`} /> },
             { title: "Global Stocks ", component: <BusinessGrowthTab param={'&inst_type1=USAIDR'} tabkey="global-stocks" dataapi={`/api/homepage-business-growth?segment=GLOBAL_STOCKS`} /> },
            ]}/>
        </div>
    );
}

export default BusinessGrowth;