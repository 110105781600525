import React from 'react';
import NavTabs from './components/nav-tabs';
import Notes from './components/notes';
// import DerivativeWatchFilters from './components/derivative-watch-filters';
import DerivativesDataTable from './components/derivatives-data-table';
import DerivativesDropdownTab from './components/derivatives-dropdown-tab';
import UsStockDataTable from './components/us-stocks-data-table';

class DerivativesWatch extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            headerRef: props.headerRef,
            notesApi: `/api/contents/notes?url=/derivative-watch`,
            eqnotesData: [],
            comnotesData: [],
            curnotesData: [],
            paramSelected: '',
        };
        this.selectedVal = this.selectedVal.bind(this);
    }

    componentDidMount() {
        try {
            this._isMounted = true;
            this.loadNotes(this.state.notesApi);
        } catch (e) {
            console.log(e);
        }

    }
    loadNotes(api) {
        fetch(api)
            .then(resp => resp.json())
            .then((cards) => {
                let eqnotesData = [];
                let comnotesData = [];
                let curnotesData = [];
                let title = cards.content.field_note_components[0];
                eqnotesData.push(title, cards.content.field_note_components[1]);
                comnotesData.push(title, cards.content.field_note_components[3])
                curnotesData.push(title, cards.content.field_note_components[2])
                this.setState({
                    eqnotesData, comnotesData, curnotesData
                })
            })
            .catch((e) => { console.log("error in header", e) })
    }
    selectedVal(selected) {
        console.log("selected", selected)
        let param1 = selected.split(' ').slice(0, 2).join("").toUpperCase();
        let param2 = (selected.split(' ').slice(2, 3).join("")).toUpperCase();
        //console.log("params split are", param1, param2)
        let paramSelected = '';
        switch (selected) {
            case "Top 20 Contracts":
                paramSelected += 'type=top20';
                break;
            case "Top 20 Spread Contracts":
            case "Spread Contracts":
                paramSelected += 'type=topspread';
                break;
            case "Nifty 50 Futures":
            case "Nifty 50 Options":
                paramSelected += "symbol=NIFTY&instrum=" + param2.slice(0, 3);
                break;
            case "Nifty Bank Futures":
            case "Nifty Bank Options":
                paramSelected += "symbol=BANKNIFTY&instrum=" + param2.slice(0, 3);
                break;
            case "Nifty IT Futures":
            case "Nifty IT Options":
                paramSelected += "symbol=" + param1 + "&instrum=" + param2.slice(0, 3);
                break;
            case "USD Contracts":
                paramSelected += "currsymbol=USD";
                break;
            case "Rupee Derivatives Contracts":
                paramSelected += "currsymbol=INR";
                break;
            default:
                paramSelected += 'type=' + selected.split(' ').join("%20");
                break;
        }
        this.setState({
            paramSelected: paramSelected
        })
    }
    render() {
        return (
            // <div className="App">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <NavTabs tabs={[
                            {
                                title: "Equity Derivatives",
                                component: <>

                                    <DerivativesDropdownTab paramSelected={this.state.paramSelected} sendSelectedCallback={this.selectedVal}
                                        selects={[

                                            { label: "Top 20 Contracts" },
                                            { label: "Stock Options" },
                                            { label: "Stock Futures" },
                                            { label: "Index Options" },
                                            { label: "Index Futures" },
                                            // { label: "Top 20 Spread Contracts" },
                                            { sublabel: "NSE Indices" },
                                            { label: "Nifty 50 Futures" },
                                            { label: "Nifty 50 Options" },
                                            { label: "Nifty Bank Futures" },
                                            { label: "Nifty Bank Options" },
                                            { label: "Nifty IT Futures" },
                                            { label: "Nifty IT Options" },

                                        ]}
                                        tabs={[
                                            {
                                                //selectedOpen:false,
                                                closeable: true,

                                            }
                                        ]}
                                    />
                                    <DerivativesDataTable filename="Equity Derivatives" totalFooter={true} insttype={"idx"} api={`/api/derivatives-watch?inst_type1=IDX&inst_type2=STK&inst_type3=IND&` + this.state.paramSelected} />

                                    <div className="col-md-12">
                                        <Notes notesData={this.state.eqnotesData} />
                                    </div>

                                </>

                            },
                            {
                                title: "Currency Derivatives", component: <>
                                    <DerivativesDropdownTab paramSelected={this.state.paramSelected} sendSelectedCallback={this.selectedVal}
                                        selects={[
                                            { label: "USD Contracts" },
                                            { label: "Rupee Derivatives Contracts" },
                                            // { label: "Spread Contracts" }
                                        ]}
                                        tabs={[
                                            {
                                                closeable: true,
                                            }
                                        ]}
                                    />
                                    <DerivativesDataTable filename="Currency Derivatives" totalFooter={true} insttype={"cur"} api={`/api/derivatives-watch?inst_type1=CUR&` + this.state.paramSelected} />

                                    <div className="col-md-12">
                                        <Notes notesData={this.state.curnotesData} />
                                    </div>


                                </>
                            },
                            {
                                title: "Bond Futures", component: <>
                                    <DerivativesDropdownTab paramSelected={this.state.paramSelected} sendSelectedCallback={this.selectedVal}
                                        selects={[
                                            { label: "FUTCBI Contracts" },
                                           // { label: "Rupee Derivatives Contracts" },
                                            // { label: "Spread Contracts" }
                                        ]}
                                        tabs={[
                                            {
                                                closeable: true,
                                            }
                                        ]}
                                    />
                                    <DerivativesDataTable filename="Bond Derivatives" totalFooter={true} insttype={"cbi"} api={`/api/derivatives-watch?inst_type1=CBI&` } />

                                    <div className="col-md-12">
                                        <Notes notesData={this.state.curnotesData} />
                                    </div>


                                </>
                            },
    //                        {
  //                              title: "Commodity Derivatives", component: <>
//
                      //              {/* <DerivativeWatchFilters /> */}
                    //                <DerivativesDropdownTab paramSelected={this.state.paramSelected} sendSelectedCallback={this.selectedVal}
                  //                      selects={[
                //                            { label: "Top 20 Contracts" },
              //                          ]}
            //                            tabs={[
          //                                  {
        //                                        closeable: true,
      //                                      }
    //                                    ]}
  //                                  />
//
      //                              {/* <div className="col-md-12">
    //                                        <Notes notesData={this.state.comnotesData} />
  //                                      </div> */}
//
                            //    </>
                            //},


                            // {
                            //     title: "US Stocks", component: <>

                            //         {/* <DerivativeWatchFilters /> */}
                            //         <UsStockDataTable filename="US Stocks" muitable={true} page={"home"} search={true} insttype={"stocks"} csv={false} viewbutton={true} api={`/api/us-stocks?type=stocks&page=live`} />

                            //         {/* <div className="col-md-12">
                            //                 <Notes notesData={this.state.comnotesData} />
                            //             </div> */}

                            //     </>
                            // },

                        ]} />
                    </div>
                </div>

            </div>
            // </div>
        );
    }
}

export default DerivativesWatch;
